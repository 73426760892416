import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const handledata = async (items) => {
	const post = [];
	var e = '';
	var next = '';

	await items.data.data.children.map((item, i, arr) => {
		let addPost = {},
			parentImg = [],
			url = '',
			picture = false,
			video = false,
			gif = false;
		if (arr.length === i + 1) {
			next = 't3_' + item.data.id;
		}

		url = 'https://www.reddit.com' + item.data.permalink;

		if (!item.data.hasOwnProperty('media_metadata')) {
			if (
				item.data.preview?.images[0]?.variants?.mp4?.source?.url !== undefined
			) {
				parentImg = item.data.preview.images[0].variants.mp4.source;
				video = false;
				gif = true;
			}

			if (item.data.preview?.images[0]?.resolutions[2]?.url !== undefined) {
				parentImg = item.data.preview.images[0].resolutions[2];
				try {
					if (item.data.preview?.reddit_video_preview?.fallback_url) {
						video = true;
					} else {
						picture = true;
					}
				} catch {}
				gif = false;
			}
			addPost = {
				id: item.data.id + Math.floor(100000000 + Math.random() * 900000000),
				partof: item.data.id,
				picture: picture,
				gallery: false,
				video: video,
				gif: gif,
				nsfw: item.data.over_18,
				title: item.data.title,
				author: item.data.author,
				url: 'https://www.reddit.com' + item.data.permalink,
				source_url: item.data.url_overridden_by_dest,
				small_img: parentImg.url,
				small_img_width: parentImg.width,
				small_img_height: parentImg.height,
			};

			if (addPost.small_img !== undefined) {
				post.push(addPost);
			}
		} else {
			const parent = item.data.media_metadata;
			for (i = 0; i < Object.entries(parent).length; i++) {
				try {
					const parentGallery =
						item.data.media_metadata[Object.entries(parent)[i][0]];
					url = 'https://www.reddit.com' + item.data.permalink;
					let isG = true;
					if (Object.entries(parent).length <= 1) isG = false;

					addPost = {
						id:
							item.data.id + Math.floor(100000000 + Math.random() * 900000000),
						partof: item.data.id,
						picture: picture,
						gallery: isG,
						video: false,
						gif: false,
						nsfw: item.data.over_18,
						author: item.data.author,
						title: item.data.title,
						url: url,
						source_url: item.data.url_overridden_by_dest,
						small_img: parentGallery.p[3].u,
						small_img_width: parentGallery.p[3].x,
						small_img_height: parentGallery.p[3].y,
					};
					post.push(addPost);
				} catch (err) {
					console.error(item.data.id + ' - ' + err);
				}
			}
		}
		return null;
	});
	if (post.length === 0) throw new Error('No images found in sub.');
	return { data: { post }, next: { next }, error: { e } };
};

function Fetchdata(sub, page, toload) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [list, setList] = useState([]);
	const [next, setNext] = useState('');
	const [hasMore, setHasMore] = useState(true);

	let prefix = '';
	if (sub.indexOf('user') === -1 && sub.indexOf('r/') === -1) prefix = 'r/';

	const apiUrl =
		'https://www.reddit.com/' +
		prefix +
		sub +
		'/.json?limit=' +
		toload +
		'&after=' +
		page;

	const sendQuery = useCallback(async () => {
		setError(false);
		setLoading(true);
		await axios
			.get(apiUrl)
			.then((data) => {
				if (data.status === 200) {
					const dataLength = data.data.data.children.length - 1;
					if (dataLength + 1 < toload) setHasMore(false);
					const after = 't3_' + data.data.data.children[dataLength].data.id;
					if (after) setNext(after);

					handledata(data)
						.then((response) => {
							setList((prev) => [...prev, ...response.data.post]);
						})
						.catch((error) => {
							//setList([...[]]);
							console.error(error.message);
							setError(error.message);
							return false;
						});
				}
			})
			.catch(() => {
				//setList([...[]]);
				setHasMore(false);
				setError('Problem fetching "' + sub + '". Is sub okay? 🧐');
			});
		setLoading(false);
		return null;
	}, [apiUrl, sub, toload]);

	useEffect(() => {
		sendQuery(sub);
	}, [sub, sendQuery]);

	return { loading, error, list, next, hasMore };
}

export default Fetchdata;
