import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
const topSub = require('./fixed.json');

export default class Child extends Component {
	render() {
		return (
			<form onSubmit={this.props.handleSubmit}>
				<Autocomplete
					fullWidth
					onChange={(e) => this.props.setQueryValue(e.target.value)}
					id="subinput"
					value={this.props.sub}
					freeSolo
					options={topSub.map((option) => option.targetSubreddit)}
					renderInput={(params) => <TextField {...params} />}
				/>
			</form>
		);
	}
}
