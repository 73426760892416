import React, { useState, useRef, useCallback, useEffect } from 'react';
import fetchData from './component/fetchData';
import Masonry from './component/react-masonry-css';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import Subinput from './component/input';
import LoadingGif from './gfx/Ajux_loader.gif';
import handlestorage from './component/handlelocalstorage';
import './App.css';

var wantDark;
let stored = handlestorage('get', 'darkmode');
if (stored !== '') {
	wantDark = handlestorage('get', 'darkmode');
} else {
	if (
		window.matchMedia &
		window.matchMedia('(prefers-color-scheme: dark)').matches
	) {
		wantDark = true;
		handlestorage('set', 'darkmode', true);
	} else {
		wantDark = false;
		handlestorage('set', 'darkmode', false);
	}
}

const sanitizeUrl = (url) => {
	if (!url.includes('reddit.com/')) {
		return url.toLowerCase();
	} else {
		var tmpUrl = url.slice(prevsub.indexOf('reddit.com/') + 10).toLowerCase();
		return tmpUrl;
	}
};

var prevsub = handlestorage('get', 'sub');
const params = new Proxy(new URLSearchParams(window.location.search), {
	get: (searchParams, prop) => searchParams.get(prop),
});
if (params.r !== null) prevsub = params.r;
if (prevsub === null || prevsub === 'undefined') prevsub = 'r/analog';
const toload = 60;

function App() {
	const [query, setQuery] = useState(sanitizeUrl(prevsub) || 'r/analog/top');
	const [queryValue, setQueryValue] = useState(query);
	const [page, setPage] = useState(null);
	const [darkmode, setDarkmode] = useState(wantDark);
	const [menu, setMenu] = useState(false);
	const [help, setHelp] = useState(false);
	const [allowNsfw, setAllowNsfw] = useState(
		handlestorage('get', 'nsfw') || false
	);

	const { loading, error, list, next, hasMore } = fetchData(
		query,
		page,
		toload
	);

	const breakpointColumnsObj = {
		default: 4,
		1200: 3,
		960: 2,
		500: 1,
	};

	useEffect(() => {
		if (darkmode) document.body.classList.add('darkmode');
		if (!darkmode) document.body.classList.remove('darkmode');
		handlestorage('set', 'darkmode', darkmode);
	}, [darkmode]);

	const handleSubmit = (e) => {
		e.preventDefault();
		var newlocation = sanitizeUrl(queryValue);
		handlestorage('set', 'sub', newlocation);
		setQuery(newlocation);
		window.location.search = '?r=' + newlocation;
	};

	const toggleSet = (e) => {
		const elements = document.getElementsByClassName(e);
		const blackout = document.querySelector('.blackout');
		blackout.style.height = document.body.scrollHeight + 'px';

		if (elements[1].style.display === 'none') {
			blackout.classList.add('on');
			elements[0].classList.add('gallery');
			for (let i = 1; i < elements.length; i++) {
				elements[i].style.display = 'block';
			}
		} else {
			blackout.classList.remove('on');
			for (let i = 1; i < elements.length; i++) {
				elements[i].style.display = 'none';
			}
			elements[0].classList.remove('gallery');
			window.scrollTo({
				top: elements[0].parentElement.offsetTop - 20,
				left: 0,
				behavior: 'smooth',
			});
		}
	};
	const useInfiniteScroll = (loading) => {
		const observer = useRef();
		const lastElementRef = useCallback(
			(node) => {
				if (loading || !hasMore) return;
				if (observer.current) observer.current.disconnect();
				observer.current = new IntersectionObserver((entries) => {
					if (entries[0].isIntersecting) {
						console.log('Loading more: ' + next);
						setPage(next);
					}
				});
				if (node) observer.current.observe(node);
			},
			[loading]
		);
		return [lastElementRef];
	};

	const [lastElementRef] = useInfiniteScroll(loading);

	function style(props, context) {
		if (context === 'sfw') {
			return {
				aspectRatio:
					'' + props.small_img_width + '/' + props.small_img_height + '',
				backgroundImage: 'url(' + LoadingGif + ')',
			};
		} else {
			return {
				aspectRatio:
					'' + props.small_img_width + '/' + props.small_img_height + '',
			};
		}
	}
	return (
		<div>
			{menu && (
				<header>
					<div className="info">
						{!help && (
							<div className="intro">
								<h3>ReddiWall</h3>
								<p className="small">
									A reddit image wall for all your scrolling needs.
								</p>
								<p className="small">by</p>
								<div className="logo">
									<a href="http://www.146.se">
										<h1>
											14<span className="reverse">6</span>
											<span className="small">
												.S<span className="rotate">E</span>
											</span>
										</h1>
										<h2 className="slogan">Design | Layout | Photography</h2>
									</a>
								</div>
							</div>
						)}
						{help && (
							<div className="help">
								<h3>Help:</h3>
								<p>
									You can display images from subreddits, users and multis. Use
									the following format:
								</p>
								<p>
									r/reddit
									<br />
									user/reddit
									<br />
									user/redit/m/multi
								</p>
							</div>
						)}
					</div>
					<div className="sub">
						<Subinput
							sub={query}
							setQueryValue={setQueryValue}
							handleSubmit={handleSubmit}
						/>
					</div>
					<div className="settings">
						<span>
							<Icon className="icons" onClick={() => setDarkmode(!darkmode)}>
								{!darkmode ? `dark_mode` : `light_mode`}
							</Icon>

							<Icon className="icons" onClick={() => setHelp(!help)}>
								{help ? `closeicon` : `help`}
							</Icon>

							<Icon
								className="icons"
								onClick={() => {
									setAllowNsfw(!allowNsfw);
									handlestorage('set', 'nsfw', !allowNsfw);
								}}
							>
								{allowNsfw ? `closeicon` : `18_up_rating`}
							</Icon>
						</span>
						<Icon className="icons" onClick={() => setMenu(!menu)}>
							closeicon
						</Icon>
					</div>
				</header>
			)}
			{!menu && (
				<div className="menucontainer">
					<div
						className={`menu ${!loading ? '' : ' loading'}`}
						onClick={() => setMenu(!menu)}
					></div>
				</div>
			)}

			<div className="repo-container">
				<Masonry
					breakpointCols={breakpointColumnsObj}
					className="my-masonry-grid"
					columnClassName="my-masonry-grid_column"
				>
					{list.map((entry, i, arr) => {
						let lastPost = false;
						let isSet = false;
						let firstGalleryPost = false;
						let lastGalleryPost = false;

						if (
							i > 1 &&
							i + 1 < arr.length &&
							arr[i - 1].partof !== entry.partof &&
							arr[i + 1].partof === entry.partof
						) {
							firstGalleryPost = true;
						}
						if (
							i > 0 &&
							i + 1 < arr.length &&
							arr[i - 1].partof === entry.partof &&
							arr[i + 1].partof !== entry.partof
						) {
							lastGalleryPost = true;
						}
						if (i - 1 > 0 && arr[i - 1].partof === entry.partof) {
							isSet = true;
						}
						if (parseInt(i + toload / 2) === arr.length) {
							lastPost = true;
						}

						return (
							<div
								className="lastpost-container"
								key={entry.id}
								height={parseInt(entry.small_img_height)}
								forced={`${isSet}`}
							>
								{lastPost && <i ref={lastElementRef}></i>}
								<div
									lastpost={`${lastPost}`}
									className={`postfull ${entry.partof} ${
										isSet ? `gallery` : ``
									}`}
									style={{
										display: isSet && !firstGalleryPost ? 'none' : 'block',
									}}
								>
									{!entry.nsfw || allowNsfw ? (
										<a
											href={entry.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												style={style(entry, 'sfw')}
												src={entry.small_img.replace(/&amp;/g, '&')}
												alt={`${entry.title} 
					By: ${entry.author}`}
												title={`${entry.title} 
					By: ${entry.author}`}
											/>
										</a>
									) : (
										<div className="nsfw" style={style(entry)}>
											NSFW!
										</div>
									)}

									<div className="info">
										<span>
											<Icon
												onClick={() => window.open(`?r=/user/${entry.author}`)}
												className="icons"
												title={`Link to ${entry.author}`}
											>
												personicon
											</Icon>

											<Icon className="icons">info</Icon>
										</span>

										<Icon
											className="icons"
											onClick={() => window.open(entry.source_url)}
										>
											{entry.picture && `image`}
											{entry.video && `videocam`}
											{entry.gif && `gif_box`}
										</Icon>

										{entry.gallery && !isSet && (
											<Icon
												className="icons galleryicon"
												onClick={() => toggleSet(entry.partof)}
											>
												collectionsicon
											</Icon>
										)}
										{entry.gallery && lastGalleryPost && (
											<Icon
												className="icons galleryicon"
												onClick={() => toggleSet(entry.partof)}
											>
												closeicon
											</Icon>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</Masonry>
			</div>
			{!hasMore && <h1 className="modal">The end 😞</h1>}
			{loading && (
				<h1 className="modal">
					<CircularProgress color="inherit" />
				</h1>
			)}
			{error && (
				<div className="modal">
					<h1>Error!</h1>
					<p>{error}</p>
				</div>
			)}
		</div>
	);
}
export default App;
