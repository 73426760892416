const handlestorage = (action, key, value) => {
	switch (action) {
		case 'set':
			localStorage.setItem(key, value);
			break;

		case 'get':
			const ls = localStorage.getItem(key) || '';
			if (ls === 'true') return true;
			if (ls === 'false') return false;
			return ls;

		case 'clear':
			localStorage.clear();
			break;

		default:
	}
};
export default handlestorage;
